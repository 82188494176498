.carousel {
  height: 75%;
}

#swag-shop {
  overflow: visible;
  padding-right: 30px;
  padding-left: 30px;
}

@media only screen and (min-width: 401px) {
  .carousel .slider-wrapper {
    width: 55%;
  }
}

@media only screen and (max-width: 400px) {
  .carousel .slider-wrapper {
    width: 100%;
  }
}

/* This is for hackgt8
/* Other goals: minimize the number of !importants used */

/* Futura font */
/* @import url("https://use.typekit.net/djq6mbs.css");



@font-face {
    font-family: "ServiceStation";
    src: url("assets/fonts/service-station-display/ServiceStation-Regular.woff")
        format("woff");
    font-weight: normal;
}

@font-face {
    font-family: "ServiceStation";
    src: url("assets/fonts/service-station-display/ServiceStation-RegularItalic.woff")
        format("woff");
    font-style: italic;
}

@font-face {
    font-family: "ServiceStation";
    src: url("assets/fonts/service-station-display/ServiceStation-Rough.woff")
        format("woff");
    font-weight: bold;
}

@font-face {
    font-family: "ServiceStation";
    src: url("assets/fonts/service-station-display/ServiceStation-RoughItalic.woff")
        format("woff");
    font-style: italic;
    font-weight: bold;
} */

.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

.login {
  min-height: 100%;
  min-width: 100%;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 40vh;
}

.button {
  height: 100%;
  width: auto;
  padding: 0.5rem 0.2rem !important;
  border-radius: 15px;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  /* background-color: #B6CBF5; */
  /* background: url(./assets/svgs/wood.svg) center center !important; */
  /* box-shadow: 0 0 px 0px rgb(212, 203, 167) !important; */
  cursor: pointer;
  color: rgba(81, 75, 231) !important;
  text-align: center;
  display: inline-block;
  margin: 0px;
  /* border: solid black; */
}

.button:hover {
  /* box-shadow: 0 0 10px 5px rgba(146, 143, 240) !important; */
  color: rgba(124, 120, 237) !important;
}

.button:focus {
  outline: none;
}

.button:active {
  box-shadow: none;
}

.menu,
.item {
  background-color: rgb(255, 255, 255, 0.7);
}

.active {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#pointIndicator {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 3rem;
  color: rgb(188, 147, 242);
  font-weight: bolder;
}

/* use this for that glowing background */
/* unfortunately the font for both of the below may not work */
/* not even !important converts it to Futura :( */
.glowing {
  background: #f4eac5;
  box-shadow: 0 0 12px 3px rgba(244, 234, 197, 1);
  border-radius: 30px;
  color: rgb(43, 57, 96);
  padding: 15px;
}

/* use this for outline background */
.outline {
  border-radius: 30px;
  border: rgb(255, 255, 255, 1) solid 1px !important;
  padding: 15px;
}

#hotItem {
  background-color: #a8a5f3;
  box-shadow: 0 0 px 0px rgb(124, 120, 237) !important;
}
